import './bootstrap';
import '../css/app.css'

import 'notivue/notification.css' // Only needed if using built-in notifications
import 'notivue/animations.css' // Only needed if using built-in animations

import {createApp, h} from 'vue'
import {createInertiaApp, router} from '@inertiajs/vue3'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from 'ziggy-js'
import * as Sentry from '@sentry/vue'
import { createNotivue } from 'notivue'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel'

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({el, App, props, plugin}) {

		const app = createApp({render: () => h(App, props)})

		if (import.meta.env.VITE_APP_ENV !== 'local') {
			Sentry.init({
				app,
				dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
				environment: import.meta.env.VITE_APP_ENV,
				tracesSampleRate: 1.0, // Capture 100% of the transactions
				replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
				replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			})
		}

        const notivue = createNotivue({
            position: 'top-right',
            // limit: 4,
            enqueue: true,
            // avoidDuplicates: true,
            notifications: {
                global: {
                    duration: 10000
                }
            }
        })

		app
			.use(plugin)
			.use(ZiggyVue, Ziggy)
			.use(notivue)
			.mount(el)

		return app

		// return createApp({render: () => h(App, props)})
		//     .use(plugin)
		//     .use(ZiggyVue, Ziggy)
		//     .mount(el);
	},
	progress: {
		color: '#289AE0',
	},
})
